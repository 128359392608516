<template>
  <div>
    <div>
      <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="row">
          <center>
            <p class="title">
                {{title}}</p>
          </center>
        </div>
      </div>
      <br>
      <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="row">
          <center>
            <img src="../../../assets/Cuenta/PagoParteSocial/pago-exitoso.svg" class="img-responsive" width="150" height="140"/>
          </center>
        </div>
      </div>
      <br>
      <br>
      <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="row">
          <center>
            <p class="description">{{description}}</p>
          </center>
        </div>
      </div>
      <br>
      <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="row">
          <div class="col col-sm-4 mt-8">
          </div>
          <div class="col col-sm-4 mt-8">
            <center>
                <button class="" type="button" v-on:click="redirect()" id="button_popups">Ir a página principal</button>
            </center>
          </div>
          <div class="col col-sm-4 mt-8">
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import('@/assets/css/stylesheet.css')
export default {
  data() {
    return {}
  },
  name: 'ProcesoExitosoComponent',
  props: {
    title: {
      type: String,
      default: '¡Pago Exitoso!'
    },
    description: {
      type: String,
      default: 'Su pago se ha realizado correctamente.'
    }
  },
  methods: {
    redirect() {
      // window.location.href ="/cuenta/principal";
      this.$router.push({path:"/cuenta/principal"});
    }
  }
}
</script>

<style scoped>
#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}
</style>